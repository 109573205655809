import clsx from 'clsx';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { ChangeEvent, FC, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { BrandAd } from '../../api';
import AdsSummary from '../../components/AdsSummary/AdsSummary';
import ListAds from '../../components/ListViews/ListAds/ListAds';
import Button from '../../components/UI/Button/Button';
import Icon from '../../components/UI/Icon/Icon';
import Loader from '../../components/UI/Loader/Loader';
import Modal from '../../components/UI/Modal/Modal';
import { BrandContext } from '../../context/BrandContext';
import useBrandAds from '../../services/queries/useBrandAds';
import useBrandsById from '../../services/queries/useBrandById';
import { postUrlAd } from '../../services/queries/userManagement';

const Ads: FC = () => {
  const { setBrand } = useContext(BrandContext);
  const { t } = useTranslation('ads');
  const { brandId } = useParams();
  const nav = useNavigate();
  const [confirmDownload, setConfirmDownload] = useState<BrandAd | null>(null);
  const [clickThroughUrl, setClickThroughUrl] = useState<string>('');
  const [advertCreativeImage, setAdvertCreativeImage] = useState<string | null>(null);
  const [advertCreativeBlob, setAdvertCreativeBlob] = useState<Blob | null>(null);
  const [advertCreativeImageError, setAdvertCreativeImageError] = useState<string | null>(
    null
  );
  const { data: brand, refetch: getBrandById } = useBrandsById(brandId);
  const {
    data: adverts,
    refetch: getBrandAds,
    isLoading: adsLoading,
  } = useBrandAds(brandId);

  useEffect(() => {
    getBrandAds();
    getBrandById().then(res => setBrand(res.data));
    // generateShortUrl();
  }, []);

  useEffect(() => {
    document.title = brand ? brand.displayName : 'Tickle';

    return () => {
      document.title = 'Tickle';
    };
  }, [brandId]);

  async function fetchImageAsBlob(imageUrl: string): Promise<Blob> {
    try {
      const response = await fetch(imageUrl, { method: 'GET' });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to fetch image: ${errorText}`);
      }

      return await response.blob();
    } catch (error) {
      console.error('Error fetching the image:', error);
      throw error; // Re-throw to handle the error at the caller level
    }
  }

  const updatePlaceholders = async (
    fileContents: string,
    clickUrl: string,
    ad: BrandAd
  ): Promise<Blob> => {
    const config = {
      linkHost: process.env.REACT_APP_DYNAMIC_LINK_HOST || '',
      appBundleId: process.env.REACT_APP_IOS_BUNDLE_ID || '',
      appStoreId: process.env.REACT_APP_APPLE_APP_STORE_ID || '',
      playStoreId: process.env.REACT_APP_PLAY_STORE_ID || '',
      googleBucket: process.env.REACT_APP_GOOGLE_BUCKET || '',
      pixelInteractionBaseUrl: process.env.REACT_APP_GATEWAY_URL || '',
    };
    fileContents = fileContents.replaceAll(/%TICKLE:AD_ID%/g, ad.id);
    fileContents = fileContents.replaceAll(
      /%TICKLE:DYNAMIC_LINK_HOST%/g,
      config.linkHost
    );
    fileContents = fileContents.replaceAll(/%TICKLE:APP_BUNDLE_ID%/g, config.appBundleId);
    fileContents = fileContents.replaceAll(/%TICKLE:APP_STORE_ID%/g, config.appStoreId);
    fileContents = fileContents.replaceAll(/%TICKLE:PLAY_STORE_ID%/g, config.playStoreId);
    fileContents = fileContents.replaceAll(
      /%TICKLE:GOOGLE_BUCKET%/g,
      `${config.googleBucket}/PkPasses`
    );
    fileContents = fileContents.replaceAll(
      /%TICKLE:PIXEL_INTERACTION_URL%/g,
      `${config.pixelInteractionBaseUrl}/ad/post-event`
    );
    if (ad.googleWalletPassUrl === '') {
      const refetchedAd = adverts?.ads.find(a => a.id === ad.id);
      ad.googleWalletPassUrl = refetchedAd?.googleWalletPassUrl ?? '';
    }

    fileContents = fileContents.replaceAll(
      /%TICKLE:GOOGLE_WALLET_PASS%/g,
      ad.googleWalletPassUrl
    );

    fileContents = fileContents.replaceAll(/%TICKLE:AD_URL%/g, clickUrl);
    fileContents = fileContents.replaceAll(
      /%TICKLE:IS_WHITE_LABEL%/g,
      `${ad.isWhiteLabel}`
    );

    return new Blob([fileContents], { type: 'text/html' });
  };

  const processContents = (
    contents: JSZip,
    outZip: JSZip,
    clickUrl: string,
    ad: BrandAd,
    imageBlob: Blob
  ): Promise<unknown> => {
    const promises = Object.keys(contents.files).map(async filename => {
      const googleBucket = process.env.REACT_APP_GOOGLE_BUCKET || '';
      let getFile: Blob | string | ArrayBuffer | undefined;

      if (filename.endsWith('index.html')) {
        getFile = await contents.files[filename]
          .async('string')
          .then(string => updatePlaceholders(string, clickUrl, ad));
      } else if (filename.endsWith('ad.png')) {
        getFile = imageBlob;
      } else if (filename.endsWith('savetowalletios.png') && ad.isWhiteLabel) {
        getFile = await fetchImageAsBlob(
          `${googleBucket}/whiteLabels/${ad.id}/savetowalletios.png`
        );
      } else if (filename.endsWith('savetowalletandroid.png') && ad.isWhiteLabel) {
        getFile = await fetchImageAsBlob(
          `${googleBucket}/whiteLabels/${ad.id}/savetowalletandroid.png`
        );
      } else if (filename.endsWith('swipetosave.png') && ad.isWhiteLabel) {
        getFile = await fetchImageAsBlob(
          `${googleBucket}/whiteLabels/${ad.id}/swipetosave.png`
        );
      } else if (filename.endsWith('Bounce.png') && ad.isWhiteLabel) {
        getFile = await fetchImageAsBlob(
          `${googleBucket}/whiteLabels/${ad.id}/Bounce.png`
        );
      } else {
        getFile = await contents.files[filename]?.async('blob');
      }

      // Ensure getFile is valid before adding to ZIP
      if (!getFile) {
        console.warn(`Skipping file: ${filename} - no valid data to process.`);
        return null;
      }

      return outZip.file(filename, getFile);
    });

    return Promise.all(promises);
  };

  const startDownload = (clickUrl: string, ad: BrandAd, image: Blob) => {
    postUrlAd({ url: clickUrl, adId: ad.id }, `/adurl`, (res: any) => {
      console.log('response URL', res);
    });

    const inZip = new JSZip();
    const outZip = new JSZip();
    fetch('/assets/new_live.zip')
      .then(res => res.blob())
      .then(templateData => inZip.loadAsync(templateData))
      .then(contents => processContents(contents, outZip, clickUrl, ad, image))
      .then(() => outZip.generateAsync({ type: 'blob' }))
      .then(output =>
        saveAs(output, `${brand?.displayName?.split(' ')?.join('_')}_${ad.id}.zip`)
      )
      .catch(error => {});
  };

  const handleDownload = async (ad: BrandAd) => {
    try {
      if (!ad.googleWalletPassUrl) {
        console.log(`googleWalletPassUrl for ad ${ad.id} is empty. Refetching data...`);
        await getBrandAds();
      }

      setConfirmDownload(ad);
    } catch (error) {
      console.error('Error during handleDownload:', error);
      alert(t('download-error'));
    }
  };

  function handleAdCreativeImage(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files && event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        if (img.width === 300 && img.height === 250) {
          setAdvertCreativeImage(reader.result as string);
          setAdvertCreativeImageError(null);

          const canvas = document.createElement('canvas');
          canvas.width = 300;
          canvas.height = 250;

          const ctx = canvas.getContext('2d');
          if (ctx === null) {
            throw new Error('Unable to get canvas context');
          } else {
            ctx.drawImage(img, 0, 0, 300, 250);
          }

          canvas.toBlob(data => {
            if (data != null) {
              setAdvertCreativeBlob(data);
              setAdvertCreativeImage(reader.result as string);
              setAdvertCreativeImageError(null);
            } else {
              setAdvertCreativeBlob(null);
              setAdvertCreativeImage(null);
              setAdvertCreativeImageError('There was an error processing the image');
            }
          }, 'image/png');
        } else {
          setAdvertCreativeImage(null);
          setAdvertCreativeImageError(
            `Image size must be 300x250. Supplied image was ${img.width}x${img.height}`
          );
        }
      };
      img.src = reader.result as string;
    };

    reader.readAsDataURL(file as Blob);
  }

  if (adsLoading) {
    return <Loader />;
  }

  return (
    <div className="wrapper" data-testid="ads-container">
      <h1>{brand?.displayName}</h1>

      <AdsSummary
        ads={adverts?.totals?.ads}
        offers={adverts?.totals?.offers}
        boosts={adverts?.totals?.boosts}
        ctaText={t('button.create')}
        ctaAction={() => {
          nav(`${window.location.pathname}/create`);
        }}
      />
      <div>
        {adverts?.ads && adverts.ads.length > 0 ? (
          <div className="flex flex-col bg-white rounded-md w-[1000px]">
            <div className="flex flex-row item-center w-full text-white  bg-[#474747]">
              <div style={{ width: '18%' }} className="font-semibold p-4">
                Media
              </div>
              <div style={{ width: '43%' }} className="font-semibold p-4">
                Title
              </div>
              <div style={{ width: '10%' }} className="font-semibold p-4">
                Status
              </div>
            </div>

            <div className="">
              {adverts.ads.map((ad, index) => (
                <ListAds
                  key={ad.id}
                  brandId={brandId}
                  adId={ad.id}
                  item={ad}
                  onClick={() => nav(`${window.location.pathname}/edit/${ad.id}`)}
                  index={index}
                  onDownload={async () => {
                    await handleDownload(ad);
                  }}
                />
              ))}
            </div>
          </div>
        ) : (
          <div className="w-full rounded-xl flex flex-row p-6 items-center">
            <p className="mb-3 mt-4 text-textSecondary">{t('noAds')}</p>
          </div>
        )}
      </div>
      <Modal
        type="small"
        height={advertCreativeImage ? 'h-[500px]' : 'h-[350px]'}
        isOpen={confirmDownload !== null}
        setIsOpen={() => setConfirmDownload(null)}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">{t('download-ad-title')}</h2>
            <p>{t('download-ad-message')}:</p>
            <input
              type="text"
              className={clsx('input w-full')}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setClickThroughUrl(e.target.value);
              }}
            />
          </div>
          {advertCreativeImage && (
            <img
              src={advertCreativeImage}
              className={clsx('w-40 rounded-lg')}
              alt="Uploaded Ad Creative Preview"
            />
          )}
          <div>
            <input
              type="file"
              className={clsx('w-full')}
              onChange={handleAdCreativeImage}
            />
            <div className="flex items-center gap-1">
              <p className="text-errorText text-md">{advertCreativeImageError}</p>
              {advertCreativeImageError && (
                <Icon name="error-exclamation" width="16px" height="16px" />
              )}
            </div>
          </div>
          <div className="flex justify-between">
            <Button
              type="text"
              onClick={() => {
                setConfirmDownload(null);
                setClickThroughUrl('');
                setConfirmDownload(null);
                setAdvertCreativeImage(null);
              }}
            >
              {t('download-ad-cancel')}
            </Button>
            <Button
              type="secondary"
              disabled={advertCreativeBlob === null || clickThroughUrl.length < 6}
              onClick={() => {
                if (confirmDownload !== null && advertCreativeBlob !== null) {
                  startDownload(clickThroughUrl, confirmDownload, advertCreativeBlob);
                  // advertUrlLink({ adId: confirmDownload, url: clickThroughUrl });
                }
                setClickThroughUrl('');
                setConfirmDownload(null);
                setAdvertCreativeImage(null);
              }}
            >
              {t('download-ad-confirm')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Ads;
