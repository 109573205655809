import clsx from 'clsx';
import { FC, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
// import { BoostResponse } from '../../api';
import FileUpload from '../FileUpload/FileUpload';
import { OfferFormType } from '../Forms/OfferForm/OfferForm';

import ImageUploader from '../ImageUploader/ImageUploader';
import Button from '../UI/Button/Button';
import Modal from '../UI/Modal/Modal';
import Radio from '../UI/Radio/Radio';

interface Props {
  boost: any;
  index: number;
  isDisabled: boolean;
}

const CreateOfferBoost: FC<Props> = ({ boost, index, isDisabled }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isHalo, setIsHalo] = useState(boost.type === 'HALO');
  const { t } = useTranslation('boosts');
  const {
    register,
    setError,
    clearErrors,
    control,
    formState: { errors },
    watch,
  } = useFormContext<OfferFormType>();

  const distributionUnit = watch(`boosts.${index}.halo.distributionUnit`, 0);
  const distributionUnitInterval = watch(
    `boosts.${index}.halo.distributionUnitInterval`,
    0
  );

  const { remove: removeBoost } = useFieldArray({
    control,
    name: 'boosts',
  });

  const codeOptions = [
    { id: '1', name: 'Codes', value: 'CODE' },
    { id: '2', name: 'URL', value: 'URL' },
  ];
  const [redemption, setRedemption] = useState(() => {
    if (boost.redemptionType) {
      return boost.redemptionType === 'CODE' ? codeOptions[0] : codeOptions[1];
    }
    return codeOptions[0];
  });

  return (
    <div data-testid="create-boost-container">
      {/* <Accordion
        type="boost"
        title={<h2 className="text-xl">Boost {index + 1}</h2>}
        defaultOpen
      > */}
      <div
        className="bg-white flex flex-col gap-6 p-4 rounded-b-xl"
        style={{ overflowY: 'auto' }}
      >
        <div className="flex flex-col gap-6 border px-4 py-8 rounded-xl">
          <h3 className="text-lg">{t('form.details')}</h3>
          <div className="flex flex-row justify-between flex-wrap w-full">
            <label
              style={{ width: '48%' }}
              className="flex flex-col relative font-semibold"
            >
              {t('form.title')}
              <input
                type="text"
                maxLength={50}
                className={clsx(
                  'input w-full font-normal',
                  errors.boosts?.[index]?.title && 'border-error'
                )}
                {...register(`boosts.${index}.title`, {
                  required: 'Offer boost title is required.',
                  minLength: {
                    value: 4,
                    message: 'Offer boost title must be at least 4 characters.',
                  },
                  maxLength: 50,
                })}
              />
              <span className="text-md absolute top-[74px] right-0 font-normal">
                {boost.title?.length || 0}/50
              </span>
              <p className="text-error mt-2 font-normal" role="alert">
                {errors.boosts?.[index]?.title?.message}
              </p>
            </label>

            <label
              style={{ width: '48%' }}
              className="flex flex-col relative font-semibold"
            >
              {t('form.subTitle')}
              <input
                type="text"
                maxLength={115}
                className={clsx(
                  'input w-full font-normal',
                  errors.boosts?.[index]?.subtitle && 'border-error'
                )}
                {...register(`boosts.${index}.subtitle`, {
                  required: 'Offer boost sub text is required.',
                  minLength: {
                    value: 4,
                    message: 'Offer boost sub text must be at least 4 characters.',
                  },
                  maxLength: 115,
                })}
              />
              <span className="text-md absolute top-[74px] right-0 font-normal">
                {boost.subtitle?.length || 0}/115
              </span>
              <p className="text-error mt-2 font-normal" role="alert">
                {errors.boosts?.[index]?.subtitle?.message}
              </p>
            </label>
          </div>
          <label className="flex flex-col relative font-semibold">
            {t('form.about-this-boost')}
            <textarea
              // maxLength={1000}
              style={{ height: '200px' }}
              className={clsx(
                'input w-full font-normal',
                errors.boosts?.[index]?.description && 'border-error'
              )}
              {...register(`boosts.${index}.description`, {
                required: 'Offer boost description is required.',
                minLength: {
                  value: 4,
                  message: 'Offer boost description must be at least 4 characters.',
                },
                maxLength: 1000,
              })}
            />
            <span className="text-md absolute -bottom-6 right-0 font-normal">
              {boost.description?.length || 0}/1000
            </span>
            <p className="text-error mt-2 font-normal" role="alert">
              {errors.boosts?.[index]?.description?.message}
            </p>
          </label>

          {/* <label className="flex flex-col relative font-semibold">
              Distribution Unit
              <input
                type="text"
                maxLength={50}
                className={clsx(
                  'input w-full font-normal',
                  errors.boosts?.[index]?.title && 'border-error'
                )} */}

          {/* /> */}
          {/* <span className="text-md absolute top-[74px] right-0 font-normal">
                {boost.title?.length || 0}/50
              </span> */}
          {/* <p className="text-error mt-2 font-normal" role="alert">
                {errors.boosts?.[index]?.title?.message}
              </p> */}
          {/* </label>   */}

          {/* <label className="w-[220px] relative">
                    <p className="font-semibold">Distribution Unit Time</p>
                    <Icon
                      name="time"
                      width="20px"
                      className="absolute top-[36px] left-4 z-10 pointer-events-none"
                    />
                    <input
                      type="time"
                      data-testid="start-time-input"
                      disabled={isDisabled}
                      className={clsx(
                        'input w-full pl-10',
                        errors?.beginTime && 'border-error'
                      )}
                    />
                    <span className="absolute top-[36px] right-3 text-dark-grey-text pointer-events-none">
                      UTC
                    </span>
                    {/* <p className="text-error mt-2" role="alert">
                      {errors?.beginTime?.message}
                    </p> */}
          {/* </label>         */}

          {/* <div className="flex">
            <Controller
              control={control}
              name={`boosts.${index}.type`}
              render={({ field: { onChange } }) => (
                <Checkbox
                  type="tick"
                  id={`boosts.${index}.type`}
                  name={`boosts.${index}.type`}
                  className="checked:!bg-primary !border-primary"
                  disabled={isDisabled}
                  checked={isHalo}
                  onChange={() => {
                    onChange(!isHalo ? 'HALO' : 'STANDARD');
                    setIsHalo(!isHalo);
                  }}
                />
              )}
            />
            <div className="flex flex-col">
              <p className="font-semibold">{t('form.halo-offer')}</p>
              <p>{t('form.halo-offer-details')}</p>
            </div>
          </div> */}
        </div>
        <div className="flex flex-col gap-6 border p-4 rounded-xl">
          <div
            className={clsx(
              'bg-white rounded-md h-fit p-4',
              errors.boosts?.[index]?.imagePath && 'border-error'
            )}
            data-testid="image-input"
          >
            <h3 className="text-lg">{t('form.boost-image')}</h3>
            <Controller
              control={control}
              name={`boosts.${index}.imagePath`}
              rules={{ required: 'Boost image is required.' }}
              render={({ field: { onChange } }) => (
                <ImageUploader
                  subText="or drop an image to upload"
                  criteria="Image size 900 x 750 px (.jpg .png or .webp). Maximum 5MB."
                  widthReq={900}
                  heightReq={750}
                  defaultImg={boost.imageUrl || ''}
                  imageUrlCB={onChange}
                  setErrorOffer={error => setError(`boosts.${index}.imagePath`, error)}
                  clearErrorsOffer={clearErrors}
                  imgType="OFFER_IMAGE"
                  formError={errors.boosts?.[index]?.imagePath?.message}
                />
              )}
            />
          </div>
        </div>
        <div className="flex flex-col bg-white gap-6 border p-4 rounded-xl">
          <h2 className="text-lg font-semibold">Boost redemption</h2>
          <div className="flex flex-col w-full gap-4">
            <label htmlFor="Redemption-method" className="w-[220px] relative">
              <p className="font-semibold mb-2">Redemption method</p>
              <Controller
                name={`boosts.${index}.redemptionType`}
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <Radio
                    id={`boosts.${index}.redemptionType`}
                    name="Redemption-method"
                    options={codeOptions}
                    value={redemption}
                    onChange={e => {
                      setRedemption(e);
                      onChange(e.value);
                    }}
                  />
                )}
              />
            </label>
            {redemption.id === '1' && (
              <Controller
                control={control}
                name={`boosts.${index}.csvFile`}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    type="CODES"
                    setCsvCodes={e => {
                      clearErrors(`boosts.${index}.csvFile`);
                      onChange(e);
                    }}
                    setOfferError={err => {
                      setError(`boosts.${index}.csvFile`, err);
                    }}
                  />
                )}
              />
            )}
            {redemption.id === '2' && (
              <div className="w-full">
                <p className="font-semibold">URL</p>
                <input
                  type="text"
                  id="redemption-input"
                  placeholder="www.urlexample.com/12356-wsdgbisAbc"
                  className={clsx(
                    'input w-full',
                    errors?.boosts?.[index]?.redemptionUrl && 'border-error'
                  )}
                  {...register(`boosts.${index}.redemptionUrl`, {
                    required: 'Offer redemption URL is required.',
                    pattern: {
                      value:
                        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                      message: 'Offer redemption URL is invalid.',
                    },
                  })}
                />
                <p className="opacity-60">Enter the URL which hosts your Offer</p>
              </div>
            )}
          </div>
        </div>
        {/* {isHalo && (
          <div className="flex flex-col gap-6 border p-4 rounded-xl">
            <h3 className="font-semibold">Halo Offer distribution</h3>
            <div className="flex flex-row items-center gap-3">
              <label
                className="flex flex-col relative"
                aria-label="halo boost distribution unit"
              >
                <input
                  type="number"
                  className={clsx(
                    'input w-[124px]',
                    errors.boosts?.[index]?.halo?.distributionUnit && 'border-error'
                  )}
                  {...register(`boosts.${index}.halo.distributionUnit`, {
                    required: 'Offer boost halo distribution unit is required.',
                    valueAsNumber: true,
                    min: '1',
                    max: '99999',
                  })}
                />
              </label>
              <p>in every</p>
              <label
                className="flex flex-col relative"
                aria-label="halo boost distribution interval number"
              >
                <input
                  type="number"
                  className={clsx(
                    'input w-[124px]',
                    errors.boosts?.[index]?.halo?.distributionUnitInterval &&
                    'border-error'
                  )}
                  {...register(`boosts.${index}.halo.distributionUnitInterval`, {
                    required: 'Offer boost halo distribution interval is required.',
                    valueAsNumber: true,
                    min: '1',
                    max: '99999',
                  })}
                />
              </label>
              <p>Boosts, a Halo Offer is served.</p>
            </div>
          </div>
        )} */}
        <Button
          type="cancel"
          className="font-semibold self-end"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Remove Boost
        </Button>
      </div>
      {/* </Accordion> */}
      <Modal type="small" isOpen={modalOpen} setIsOpen={setModalOpen}>
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">Remove?</h2>
            <p>Are you sure you want to remove? You will lose any data entered</p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setModalOpen(false)}>
              No, go back
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                setIsHalo(false);
                removeBoost(index);
              }}
            >
              Yes, remove
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateOfferBoost;
