import axios from 'axios';
import clsx from 'clsx';
import domtoimage from 'dom-to-image';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import QRCode from 'qrcode.react';
import { FC, useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaCopy } from 'react-icons/fa';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { EditBrandRequest } from '../../api';

import Image2 from '../../assets/shared/Brand_QR_Dark.png';
import Image9 from '../../assets/shared/Brand_QR_Preferred.png';

import Logo2 from '../../assets/shared/Feather_blue.png';

import DropdownMultiSelect from '../../components/DropdownMultiselect/DropdownMultiselect';
import DropdownSingleCountry from '../../components/DropdownMultiselect/DropdownSingleCountry';
import CreateClaim from '../../components/Forms/ClaimForm/ClaimForm';
import ImageUploader from '../../components/ImageUploader/ImageUploader';
import StickyFooter from '../../components/StickyFooter/StickyFooter';
import Button from '../../components/UI/Button/Button';
import Icon from '../../components/UI/Icon/Icon';
import Modal from '../../components/UI/Modal/Modal';
import Checkbox from '../../components/UI/Toggles/Checkbox/Checkbox';
import { BrandContext } from '../../context/BrandContext';
import useBrandsById from '../../services/queries/useBrandById';
import useCreateBrand from '../../services/queries/useCreateBrand';
import useEditBrand from '../../services/queries/useEditBrand';
import useNotifyBrand from '../../services/queries/useNotifyBrand';
import {
  deleteBrandRequest,
  getAllBrandClaims,
  getAllCountriesList,
  getCategoryRequest,
} from '../../services/queries/userManagement';

const imagesQR = [
  {
    id: 1,
    url: Image2,
    width: '140px',
    height: '153px',
    top: 13,
    left: 15,
    fgColor: '#0D172F',
  },

  {
    id: 8,
    url: Image9,
    width: '141px',
    height: '155px',
    top: 14,
    left: 14,
    right: 26,
    logo: Logo2,
    fgColor: '#0D172F',
  },
];
const firebaseurlLink = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks';
export interface IClaimSampleSelection {
  id: string;
  brandId: string;
  title: string;
  startDate: any;
  startTime: any;
  endTime: any;
  endDate: any;
  description: string;
  file: { name: string; format: string; url: string; base64: string };
}

const Brand: FC = () => {
  const { t } = useTranslation('brand');
  const { brandId } = useParams();
  const { setBrand } = useContext(BrandContext);
  const [discardModalOpen, setDiscardModalOpen] = useState(false);
  const [shortDynamicLink, setShortDynamicLink] = useState('');

  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [brandDeleteModalOpen, setBrandDeleteModalOpen] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [copyLink, setCopyLink] = useState<string>('');
  const navigate = useNavigate();
  const location = useLocation();

  const { data: brand, refetch } = useBrandsById(brandId);
  const qrCodeReference: any = useRef([]);

  useEffect(() => {
    document.title = brand ? brand.displayName : 'Tickle';
    return () => {
      document.title = 'Tickle';
    };
  }, [brand]);

  useEffect(() => {
    refetch().then(res => setBrand(res?.data));
  }, []);

  const {
    control,
    formState: { isDirty },
  } = useForm<EditBrandRequest>({
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const {
    mutate: editBrand,
    isLoading: editBrandLoading,
    isError: handleCreationFailed,
  } = useEditBrand(brandId);

  const { mutate: notifyNewBrand, isLoading: notifyNewBrandLoading } = useNotifyBrand();
  const { mutate: createBrand } = useCreateBrand();
  const magicLink = `${process.env.REACT_APP_DYNAMIC_LINK_HOST}/?link=${process.env.REACT_APP_DYNAMIC_LINK_HOST}/tickleapp/inviteuser?brandid%3D${brandId}&apn=${process.env.REACT_APP_PLAY_STORE_ID}&isi=${process.env.REACT_APP_APPLE_APP_STORE_ID}&ibi=${process.env.REACT_APP_PLAY_STORE_ID}`;
  const identityKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [checkedPartnerStatus, setCheckedPartnerStatus] = useState<boolean>(false);
  const [newsletter, setNewsletter] = useState<boolean | undefined>(false);
  const [claimSample, setClaimSample] = useState<boolean | undefined>(false);
  const [downloadApp, setDownloadApp] = useState<boolean | undefined>(false);
  const [isAutomatedEmail, setIsAutomatedEmail] = useState<boolean | undefined>(false);
  const [categoryArr, setCategoryArr] = useState<any>([]);
  const [categoryChildArr, setCategoryChildArr] = useState<any>([]);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [brandError, setBrandError] = useState<any>('');
  const [updatedBrand, setUpdatedBrand] = useState<any>();
  const [updatedCategory, setUpdatedCategory] = useState<any>();
  const [updatedChildCategory, setUpdatedChildCategory] = useState<any>();
  const [changeCategorySelection, setChangeCategorySelection] = useState<any>();
  const [brandDeleteLoader, setBrandDeleteLoader] = useState<boolean>(false);
  const [inputTextValue, setInputTextValue] = useState<string>();
  const [selectedCountry, setSelectedCountry] = useState<any>();
  const [claimSampleSelection, setClaimSampleSelection] = useState<
    IClaimSampleSelection[]
  >([]);

  const zip = new JSZip();
  const base64Files: { name: string; base64: string }[] = [];
  const base64PNGFiles: { name: string; base64: string }[] = [];

  const validateClaimSample = () => {
    const error: any = [];

    claimSampleSelection.forEach((cc, i) => {
      const c = claimSampleSelection[i];
      if (!c.title || !c.description || (!c.file.url && !c.file.name)) {
        error.push(true);
      }
    });
    return !error.includes(true);
  };

  const onSubmitEdit: any = async () => {
    if (
      (!downloadApp || (downloadApp && updatedBrand?.downloadAppImage)) &&
      ((!isAutomatedEmail &&
        (!updatedBrand?.brandContactsForClaims?.length ||
          !updatedBrand?.brandContactsForNewsLetter?.length) &&
        brandId) ||
        (!isAutomatedEmail && !brand?.id) ||
        (isAutomatedEmail &&
          (updatedBrand?.brandContactsForNewsLetter?.length ||
            updatedBrand?.brandContactsForClaims?.length)))
    ) {
      const data = updatedBrand;
      const claimSampleContacts =
        data.brandContactsForClaims?.length && data.brandContactsForClaims?.toString();
      const newsletterContacts =
        data.brandContactsForNewsLetter?.length &&
        data.brandContactsForNewsLetter?.toString();
      if (!categoryArr?.length) {
        setBrandError('Please select atleast one category');
      } else if (validateClaimSample()) {
        editBrand(
          {
            coverImagePath: data.coverImagePath,
            logoImagePath: data.logoImagePath,
            country: selectedCountry && selectedCountry?.code,
            downloadAppImage: data.downloadAppImage,
            claimSampleImage: data.claimSampleImage,
            handle: data.handle?.split(' ')?.join(''),
            name: data.displayName,
            displayName: data.displayName,
            budgetTotal: data.budgetTotal ? Number(data.budgetTotal) : 0,
            budgetRemaining: data.budgetRemaining ? Number(data.budgetRemaining) : 0,
            brandContactsForClaims: claimSampleContacts?.length
              ? claimSampleContacts?.split(/[ ,]+/)
              : [],
            brandContactsForNewsLetter: newsletterContacts?.length
              ? newsletterContacts?.split(/[ ,]+/)
              : [],
            isAutomatedEmail: data.isAutomatedEmail ? data.isAutomatedEmail : false,
            description: data.description,
            magicLink: '',
            brandWebsite: data.brandWebsite,
            numberOfCoins: data.numberOfCoins ? Number(data.numberOfCoins) : 0,
            newsletter: data.newsletter ? data.newsletter : false,
            claimSample: data.claimSample ? data.claimSample : false,
            downloadApp: data.downloadApp ? data.downloadApp : false,
            isHotKeyNotificationSent:
              (data.newsletter || data.claimSample || data.downloadApp) ?? false,
            downloadAppLink: data.downloadAppLink,
            downloadAppDescription: data.downloadAppDescription,
            downloadAppSubCopy: data.downloadAppSubCopy,
            partnerStatus: checkedPartnerStatus ? 'PARTNER' : 'GUEST',
            categories: [...categoryArr, ...categoryChildArr],
            brandClaimSamples: claimSampleSelection?.length
              ? claimSampleSelection?.map((cc: any) => {
                  return {
                    id: cc?.file?.url ? cc?.id : null,
                    brandId: brandId?.length ? brandId : '',
                    title: cc?.title,
                    description: cc?.description,
                    startDate: `${cc.startDate}T${cc.startTime}Z`,
                    endDate: `${cc.endDate}T${cc.endTime}Z`,
                    claimSampleImages: cc?.file?.url?.length
                      ? null
                      : {
                          image: cc?.file?.base64,
                          imageName: cc?.file?.name,
                          imageFormat: cc?.file?.format,
                        },
                    imageUrl: cc?.file?.url
                      ? `brand/${cc?.file?.url?.split('brand/')?.[1]}`
                      : null,
                  };
                })
              : [],
          },
          {
            onSuccess: () => {
              navigate('/partners');
            },
            onError: (err: any) => {
              setBrandError(err?.response?.data?.message);
            },
          }
        );
      }
    }
  };
  const onSubmitAdd: any = async () => {
    const data = updatedBrand;
    if (
      (!isAutomatedEmail && !brand?.id) ||
      (isAutomatedEmail &&
        (updatedBrand?.brandContactsForNewsLetter ||
          updatedBrand?.brandContactsForClaims))
    ) {
      if (!categoryArr?.length) {
        setBrandError('Please select atleast one category');
      } else if (validateClaimSample()) {
        createBrand(
          {
            handle: data.handle?.split(' ')?.join(''),
            name: data.name,
            displayName: data.displayName,
            country: selectedCountry && selectedCountry?.code,
            partnerStatus: checkedPartnerStatus ? 'PARTNER' : 'GUEST',
            budgetTotal: Number(data.budgetTotal),
            budgetRemaining: Number(data.budgetRemaining),
            brandContactsForClaims: data.brandContactsForClaims?.length
              ? data.brandContactsForClaims?.split(/[ ,]+/)
              : [],
            brandContactsForNewsLetter: data.brandContactsForNewsLetter?.length
              ? data.brandContactsForNewsLetter?.split(/[ ,]+/)
              : [],
            isAutomatedEmail: data.isAutomatedEmail ? data.isAutomatedEmail : false,
            description: data.description,
            magicLink: '',
            brandWebsite: data.brandWebsite,
            numberOfCoins: data.numberOfCoins ? Number(data.numberOfCoins) : 0,
            newsletter: data.newsletter ? data.newsletter : false,
            claimSample: data.claimSample ? data.claimSample : false,
            downloadApp: data.downloadApp ? data.downloadApp : false,
            downloadAppLink: data.downloadAppLink ? data.downloadAppLink : '',
            downloadAppDescription: data.downloadAppDescription
              ? data.downloadAppDescription
              : '',
            downloadAppSubCopy: data.downloadAppSubCopy ? data.downloadAppSubCopy : '',
            isHotKeyNotificationSent:
              (data.newsletter || data.claimSample || data.downloadApp) ?? false,
            brandClaimSamples: claimSampleSelection?.length
              ? claimSampleSelection?.map((cc: any) => {
                  return {
                    title: cc?.title,
                    description: cc?.description,
                    startDate: `${cc.startDate}T${cc.startTime}Z`,
                    endDate: `${cc.endDate}T${cc.endTime}Z`,
                    claimSampleImages: {
                      image: cc?.file?.base64,
                      imageName: cc?.file?.name,
                      imageFormat: cc?.file?.format,
                    },
                    imageUrl: null,
                  };
                })
              : [],
            categories: [...categoryArr, ...categoryChildArr],
          },
          {
            onSuccess(br) {
              navigate('/partners');
            },
            onError: (err: any) => {
              setBrandError(err?.response?.data?.message);
            },
          }
        );
      }
    }
  };

  const handleCopy = () => {
    const textArea = document.createElement('textarea');
    textArea.value = shortDynamicLink;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopyLink('Magic Link Copied!');

    setTimeout(() => {
      setCopyLink('');
    }, 1000);
  };

  const deleteABrand = () => {
    deleteBrandRequest('', `/${brandId}`, (res: any) => {
      setBrandDeleteLoader(true);
      if (res.isSuccess) {
        navigate('/partners');
        setBrandDeleteLoader(false);
      } else {
        setBrandDeleteLoader(false);
      }
    });
  };

  const getClaimSampleData = () => {
    getAllBrandClaims('', `brandclaimsample/${brandId}`, (res: any) => {
      if (res.isSuccess) {
        setClaimSampleSelection(
          res?.data?.brandClaimSamples?.map((fs: any) => {
            return {
              id: fs.id,
              brandId: fs.brandId,
              title: fs.title,
              startDate: fs.startDate?.split('T')?.[0],
              endDate: fs.endDate?.split('T')?.[0],
              startTime: fs.startDate?.split('T')?.[1]?.slice(0, -1),
              endTime: fs.endDate?.split('T')?.[1]?.slice(0, -1),
              description: fs.description,
              file: { url: fs.imageUrl, base64: '', name: '', image: '' },
            };
          })
        );
      } else {
        setClaimSampleSelection([]);
      }
    });
  };
  const generateShortUrl = async () => {
    if (!shortDynamicLink) {
      setTimeout(async () => {
        const shortLink = await axios.post(`${firebaseurlLink}?key=${identityKey}`, {
          longDynamicLink: magicLink,
          suffix: {
            option: 'UNGUESSABLE',
          },
        });
        await setShortDynamicLink(shortLink.data?.shortLink);
      }, 1000);
    }
  };

  useEffect(() => {
    if (brandId) {
      getClaimSampleData();
    } else {
      setClaimSampleSelection([]);
    }
    generateShortUrl();
  }, [brandId]);

  const downloadQRPNG = () => {
    qrCodeReference.current.forEach(async (element: any, i: number) => {
      element.style.display = 'block';

      const scale = 2;
      const options = {
        width: element.clientWidth * scale,
        height: element.clientHeight * scale,
        style: {
          transform: `scale( ${scale} )`,
          transformOrigin: 'top left',
        },
      };
      domtoimage
        .toPng(element, options)
        .then((dataUrl: any) => {
          base64PNGFiles.push({ name: `QRCode-${i + 1}.png`, base64: dataUrl });
          setTimeout(() => {
            element.style.display = 'none';
          }, 1500);
        })
        .catch((error: any) => {
          element.style.display = 'none';
        });
    });

    setTimeout(async () => {
      base64PNGFiles.forEach((s, indx) => {
        const urlPng = s?.base64?.split('base64,')[1];
        zip.file(`QRCode_${indx + 1}.png`, urlPng, { base64: true });
      });

      const zipContent = await zip.generateAsync({ type: 'blob' });
      saveAs(zipContent, `QRCode_${brand?.displayName?.split(' ')?.join('_')}.zip`);
    }, 1500);
  };

  const downloadQRSVG = () => {
    qrCodeReference.current.forEach(async (element: any, i: number) => {
      element.style.display = 'block';

      domtoimage
        .toSvg(element)
        .then((dataUrl: any) => {
          base64Files.push({ name: `QRCode-${i + 1}.svg`, base64: dataUrl });
          setTimeout(() => {
            element.style.display = 'none';
          }, 1000);
        })
        .catch((error: any) => {
          element.style.display = 'none';
        });
    });

    setTimeout(async () => {
      base64Files.forEach((s, indx) => {
        const url = s?.base64?.split('charset=utf-8,')[1];
        zip.file(`QRCode_${indx + 1}.svg`, url, { binary: true });
      });
    }, 1000);
  };

  const downloadQRCode = async () => {
    downloadQRSVG();
    downloadQRPNG();
  };

  useEffect(() => {
    if (brand) setUpdatedBrand(brand);
  }, [brand]);

  useEffect(() => {
    if (!brand || brand.partnerStatus !== 'PARTNER') {
      setCheckedPartnerStatus(false);
      setDisabled(true);
    } else {
      setCheckedPartnerStatus(true);
      setDisabled(false);
    }
  }, [brand?.partnerStatus]);

  useEffect(() => {
    if (!brand) {
      setNewsletter(false);
    } else {
      setNewsletter(brand.newsletter);
    }
  }, [brand?.newsletter]);

  useEffect(() => {
    if (!brand) {
      setIsAutomatedEmail(false);
    } else {
      setIsAutomatedEmail(brand.isAutomatedEmail);
    }
  }, [brand?.isAutomatedEmail]);

  useEffect(() => {
    if (!brand) {
      setClaimSample(false);
    } else {
      setClaimSample(brand.claimSample);
    }
  }, [brand?.claimSample]);

  useEffect(() => {
    if (!brand) {
      setDownloadApp(false);
    } else {
      setDownloadApp(brand.downloadApp);
    }
  }, [brand?.downloadApp]);

  useEffect(() => {
    if (brand && brand.followedCategories)
      setCategories(brand.followedCategories.map(item => item.name!));
  }, [brand?.followedCategories]);

  useEffect(() => {
    if (brand?.country) {
      getAllCountriesList('', '', (res: any) => {
        if (res.isSuccess) {
          setSelectedCountry(
            JSON.parse(res.data)?.filter((fl: any) => fl.code === brand?.country)?.[0]
          );
        } else {
          setSelectedCountry({});
        }
      });
    }
  }, [brand?.country]);

  const getCategoryData = () => {
    getCategoryRequest('', `category`, (res: any) => {
      console.log('Categories List', res?.data?.parentCategories);
      if (res.isSuccess) {
        setUpdatedCategory(res.data.parentCategories);
        setUpdatedChildCategory([
          ...res.data.parentCategories,
          ...res.data.childCategories,
        ]);
      } else {
        setUpdatedCategory([]);
        setUpdatedChildCategory([]);
      }
    });
  };

  useEffect(() => {
    if (!brand) getCategoryData();
  }, []);

  const keyCheck = (ev: any) => {
    if (ev.key === ' ') {
      ev.preventDefault();
    }
  };

  console.log('Updated Child Categories', updatedChildCategory);

  return (
    <div className="wrapper mb-40 items-start" data-testid="brand-container">
      <div className="flex flex-row w-full items-center justify-between">
        <div className="flex justify-start ">
          <button
            type="button"
            onClick={() => navigate(-1)}
            aria-label="go back"
            data-testid="back-button"
          >
            <Icon name="back-arrow" width="24" />
          </button>
          <h1 className="font-semibold">{`${
            location.pathname === `/brand/${brandId}` ? 'Update Brand' : 'Create Brand'
          }`}</h1>
        </div>

        {location.pathname === `/brand/${brandId}` && (
          // <h3 className="font-semibold mb-6 text-[20px]">{t('brand-qr')}</h3>
          <div className="flex flex-row justify-start items-center w-half h-[8px]">
            <Button type="primary" onClick={downloadQRCode}>
              Download QR Code
            </Button>
          </div>
        )}
      </div>

      <div className="flex flex-row flex-wrap justify-between bg-white rounded-2xl p-4 w-full">
        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <h3 className="font-semibold text-[20px] pb-4">{t('brand-name')}</h3>
          <Controller
            control={control}
            name="displayName"
            defaultValue={brand?.displayName}
            rules={{ required: 'Name is required.' }}
            render={({ field: { onChange } }) => (
              <input
                type="text"
                className={clsx(
                  'input w-full'
                  // errors?.name && 'border-error'
                )}
                defaultValue={brand?.displayName}
                onChange={(res: any) => {
                  onChange(res);
                  setUpdatedBrand({
                    ...updatedBrand,
                    displayName: res.target.value,
                    name: res.target.value,
                  });
                }}
              />
            )}
          />
        </div>
        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <h3 className="font-semibold text-[20px] pb-4">{t('brand-handle')}</h3>
          <Controller
            control={control}
            name="handle"
            defaultValue={brand?.handle}
            rules={{ required: 'Handle is required.' }}
            render={({ field: { onChange } }) => (
              <>
                <input
                  type="text"
                  className={clsx('input w-full')}
                  defaultValue={brand?.handle?.split(' ')?.join('')}
                  onKeyDown={ev => keyCheck(ev)}
                  onChange={(res: any) => {
                    onChange(res);
                    // console.log("Key Code", res.key)
                    setUpdatedBrand({ ...updatedBrand, handle: res.target.value });
                  }}
                />
              </>
            )}
          />
        </div>
        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <h3 className="font-semibold text-[20px] pb-4">{t('brand-budget')}</h3>
          <Controller
            control={control}
            name="budgetTotal"
            defaultValue={brand?.budgetTotal}
            rules={{ required: 'Budget is required.' }}
            render={({ field: { onChange } }) => (
              <>
                <input
                  type="text"
                  defaultValue={brand?.budgetTotal}
                  className={clsx('input w-full')}
                  onChange={(res: any) => {
                    onChange(res);
                    setUpdatedBrand({ ...updatedBrand, budgetTotal: res.target.value });
                  }}
                />
              </>
            )}
          />
        </div>

        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <h3 className="font-semibold text-[20px] pb-4">{t('brand-budget-remain')}</h3>
          <Controller
            control={control}
            name="budgetRemaining"
            defaultValue={brand?.budgetRemaining}
            rules={{ required: 'Remaining Budget is required.' }}
            render={({ field: { onChange } }) => (
              <>
                <input
                  type="text"
                  defaultValue={brand?.budgetRemaining}
                  onChange={(res: any) => {
                    onChange(res);
                    setUpdatedBrand({
                      ...updatedBrand,
                      budgetRemaining: res.target.value,
                    });
                  }}
                  className={clsx('input w-full')}
                />
              </>
            )}
          />
        </div>

        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <div className="flex flex-row flex-wrap w-full">
            <h3 className="font-semibold text-[20px] pb-4 pr-4">
              {t('brand-magic-link')}
            </h3>
            <FaCopy
              onClick={() => handleCopy()}
              width="24"
              className="mt-1 mr-4 cursor-pointer"
            />
            <div className="text-green-500 mt-1">{copyLink}</div>
          </div>

          <Controller
            control={control}
            name="magicLink"
            defaultValue={shortDynamicLink}
            render={({ field: { onChange } }) => (
              <>
                <input
                  type="text"
                  defaultValue={`${shortDynamicLink}`}
                  className={clsx('input w-full')}
                  onChange={(res: any) => {
                    onChange(res);
                    setUpdatedBrand({ ...updatedBrand, magicLink: res.target.value });
                  }}
                  disabled
                  // {...field}'
                />
              </>
            )}
          />
        </div>

        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <h3 className="font-semibold text-[20px] pb-4">{t('brand-website')}</h3>
          <Controller
            control={control}
            name="brandWebsite"
            defaultValue={brand?.brandWebsite}
            render={({ field: { onChange } }) => (
              <>
                <input
                  type="text"
                  defaultValue={brand?.brandWebsite ? brand?.brandWebsite : ''}
                  className={clsx('input w-full')}
                  onChange={(res: any) => {
                    onChange(res);
                    setUpdatedBrand({ ...updatedBrand, brandWebsite: res.target.value });
                  }}
                />
              </>
            )}
          />
        </div>

        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <h3 className="font-semibold text-[20px] pb-4">{t('brand-number-coins')}</h3>
          <Controller
            control={control}
            name="numberOfCoins"
            defaultValue={brand?.numberOfCoins}
            render={({ field: { onChange } }) => (
              <>
                <input
                  type="text"
                  defaultValue={brand?.numberOfCoins}
                  className={clsx('input w-full')}
                  onChange={(res: any) => {
                    onChange(res);
                    setUpdatedBrand({ ...updatedBrand, numberOfCoins: res.target.value });
                  }}
                  // {...field}
                />
              </>
            )}
          />
        </div>

        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <h3 className="font-semibold text-[20px] pb-4">{t('brand-description')}</h3>
          <Controller
            control={control}
            name="description"
            defaultValue={brand?.description}
            render={({ field: { onChange } }) => (
              <>
                <input
                  type="text"
                  defaultValue={brand?.description}
                  className={clsx('input w-full')}
                  onChange={(res: any) => {
                    onChange(res);
                    setUpdatedBrand({ ...updatedBrand, description: res.target.value });
                  }}
                  maxLength={150}
                  // {...field}
                />
              </>
            )}
          />
        </div>

        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <h3 className="font-semibold text-[20px] pb-4">{t('brand-partner-status')}</h3>
          <div className="justify-between flex mt-5 col-4 w-full">
            <Controller
              control={control}
              name="partnerStatus"
              render={({ field: { onChange } }) => (
                <Checkbox
                  type="tick"
                  name="setPartnerStatusForBrand"
                  checked={checkedPartnerStatus}
                  id={`${brand?.id}`}
                  label={t('brand-partner')}
                  onChange={(res: any) => {
                    setCheckedPartnerStatus(!checkedPartnerStatus);
                    setDisabled(!disabled);
                    onChange(res);
                    setUpdatedBrand({ ...updatedBrand, partnerStatus: res.target.value });
                  }}
                  className="checked:!bg-primary !border-primary flex"
                />
              )}
            />
            <div className="flex flex-row justify-end items-center w-half h-[8px]">
              <Button
                type="primary"
                onClick={() => setNotificationModalOpen(true)}
                disabled={disabled}
              >
                Notify Followers
              </Button>
            </div>
          </div>
        </div>

        <div
          style={{ width: '48%' }}
          className="flex flex-col bg-white rounded-2xl p-4 w-full"
        >
          <h3 className="font-semibold text-[20px] ">Category</h3>
          <div className="">
            <DropdownMultiSelect
              people={updatedCategory?.length ? updatedCategory : brand?.parentCategories}
              categorySelected={brand ? brand.followedParentCategories : []}
              backPeople={(item: any) => setCategoryArr(item)}
              inputTextValue={(val: any) => setInputTextValue(val)}
              changeCategorySelection={(flag: any) => setChangeCategorySelection(flag)}
            />
          </div>

          <div>
            <DropdownMultiSelect
              people={
                updatedChildCategory?.length
                  ? updatedChildCategory?.filter((is: any) =>
                      categoryArr?.map((it: any) => it?.id)?.includes(is?.parentId)
                    )
                  : []
              }
              categorySelected={brand ? brand.followedChildCategories : []}
              backPeople={(item: any) => setCategoryChildArr(item)}
              inputTextValue={(val: any) => console.log(val)}
              isSubcategory
              changeCategorySelection={(flag: any) => setChangeCategorySelection(flag)}
            />
          </div>

          {inputTextValue && <div className="text-red-500">{inputTextValue}</div>}
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-2xl p-8 w-full">
        <h3 className="font-semibold text-[20px] pb-4">Notification</h3>

        <Controller
          control={control}
          name="isAutomatedEmail"
          render={({ field: { onChange } }) => (
            <Checkbox
              type="tick"
              name="IsAutomatedEmail"
              checked={isAutomatedEmail}
              id={`${brand?.id}-automatedEmails`}
              label={t('brand-automate')}
              onChange={(res: any) => {
                setIsAutomatedEmail(!isAutomatedEmail);
                onChange(res);
                setUpdatedBrand({ ...updatedBrand, isAutomatedEmail: !isAutomatedEmail });
              }}
              className="checked:!bg-primary !border-primary flex"
            />
          )}
        />

        <div className="flex flex-row flex-wrap w-full justify-between">
          <div
            style={{ width: '48%' }}
            className="flex flex-col bg-white rounded-2xl pr-4 py-4 w-full"
          >
            <h3 className="font-semibold my-3 text-[20px]">
              Claim Sample Brand Contacts
            </h3>
            <div className="h-full justify-between flex col-4 w-full">
              <Controller
                control={control}
                name="brandContactsForClaims"
                defaultValue={
                  Array.isArray(brand?.brandContactsForClaims)
                    ? brand?.brandContactsForClaims.toString()
                    : brand?.brandContactsForClaims
                }
                // rules={{ required: 'Remaining Budget is required.' }}
                render={({ field: { onChange } }) => (
                  <>
                    <input
                      type="text"
                      disabled={!isAutomatedEmail}
                      defaultValue={
                        Array.isArray(brand?.brandContactsForClaims)
                          ? brand?.brandContactsForClaims.toString()
                          : brand?.brandContactsForClaims
                      }
                      className={clsx('input w-full')}
                      onChange={(res: any) => {
                        onChange(res.target.value);
                        setUpdatedBrand({
                          ...updatedBrand,
                          brandContactsForClaims: res.target.value,
                        });
                      }}
                      // {...field}
                    />
                  </>
                )}
              />
            </div>

            <div className="text-red-500 pt-2">
              {(!isAutomatedEmail && !updatedBrand?.brandContactsForClaims?.length) ||
              (!updatedBrand?.brandContactsForNewsLetter?.length && brandId) ||
              (!isAutomatedEmail && !brand?.id) ||
              (isAutomatedEmail && updatedBrand?.brandContactsForClaims?.length) ||
              updatedBrand?.brandContactsForNewsLetter?.length
                ? ''
                : 'Please select automated email, claim sample and newsletter contact'}
            </div>
          </div>

          <div
            style={{ width: '48%' }}
            className="flex flex-col bg-white rounded-2xl pl-4 py-4 w-full"
          >
            <h3 className="font-semibold my-3 text-[20px]">Newsletter Brand Contacts</h3>

            <div className="h-full justify-between flex col-4 w-full">
              <Controller
                control={control}
                name="brandContactsForNewsLetter"
                defaultValue={
                  Array.isArray(brand?.brandContactsForNewsLetter)
                    ? brand?.brandContactsForNewsLetter.toString()
                    : brand?.brandContactsForNewsLetter
                }
                // rules={{ required: 'Remaining Budget is required.' }}
                render={({ field: { onChange } }) => (
                  <>
                    <input
                      type="text"
                      disabled={!isAutomatedEmail}
                      defaultValue={
                        Array.isArray(brand?.brandContactsForNewsLetter)
                          ? brand?.brandContactsForNewsLetter.toString()
                          : brand?.brandContactsForNewsLetter
                      }
                      className={clsx('input w-full')}
                      onChange={(res: any) => {
                        onChange(res.target.value);
                        setUpdatedBrand({
                          ...updatedBrand,
                          brandContactsForNewsLetter: res.target.value,
                        });
                      }}
                      // {...field}
                    />
                  </>
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-white rounded-2xl p-8 w-full">
        <h3 className="font-semibold text-[20px] pb-4">Countries</h3>
        <Controller
          control={control}
          name="isAutomatedEmail"
          render={({ field: { onChange } }) => (
            <>
              <DropdownSingleCountry
                backSelected={(e: any) => {
                  console.log('selectedCountry Drop', e);
                  setSelectedCountry(
                    selectedCountry !== undefined && e.code === selectedCountry.code
                      ? {}
                      : e
                  );
                  onChange(e);
                }}
                selection={selectedCountry ?? {}}
              />
            </>
          )}
        />
      </div>

      {/* {location.pathname === `/brand/${brandId}` && (
        <div className="flex flex-col w-full bg-white rounded-2xl p-8">
          <h3 className="font-semibold mb-3 text-[20px]">{t('brand-qr')}</h3>

          <div className="pt-4" style={{ textAlign: 'center' }}>
            <Button type="primary" onClick={downloadQRCode}>
              Download QR Code
            </Button>
          </div>
        </div>
      )} */}

      <div className="flex flex-col bg-white rounded-2xl p-8 w-full">
        <h3 className="font-semibold text-[20px] pb-4">{t('brand-keys')}</h3>
        <div className="h-full justify-between flex col-4 w-full">
          <Controller
            control={control}
            name="newsletter"
            render={({ field: { onChange } }) => (
              <Checkbox
                type="tick"
                name="newsletter"
                checked={newsletter}
                id={`${brand?.id}-newsletter`}
                label={t('brand-newletter')}
                onChange={(res: any) => {
                  setNewsletter(!newsletter);
                  onChange(res);
                  setUpdatedBrand({ ...updatedBrand, newsletter: !newsletter });
                }}
                className="checked:!bg-primary !border-primary flex"
              />
            )}
          />

          <Controller
            control={control}
            name="claimSample"
            render={({ field: { onChange } }) => (
              <Checkbox
                type="tick"
                name="claimSample"
                checked={claimSample}
                id={`${brand?.id}-claimSample`}
                label={t('brand-claim')}
                onChange={(res: any) => {
                  setClaimSample(!claimSample);
                  onChange(res);
                  setUpdatedBrand({ ...updatedBrand, claimSample: !claimSample });
                }}
                className="checked:!bg-primary !border-primary flex"
              />
            )}
          />

          <Controller
            control={control}
            name="downloadApp"
            render={({ field: { onChange } }) => (
              <Checkbox
                type="tick"
                name="downloadApp"
                checked={downloadApp}
                id={`${brand?.id}-downloadApp`}
                label={t('brand-download')}
                onChange={(res: any) => {
                  setDownloadApp(!downloadApp);
                  onChange(res);
                  setUpdatedBrand({ ...updatedBrand, downloadApp: !downloadApp });
                }}
                className="checked:!bg-primary !border-primary flex"
              />
            )}
          />
        </div>

        <div className="flex flex-row flex-wrap w-full justify-between">
          <div
            style={{ width: '48%' }}
            className="flex flex-col bg-white rounded-2xl pr-4 py-4 w-full"
          >
            <h3 className="font-semibold my-3 text-[20px]">Download App Title</h3>
            <Controller
              control={control}
              name="downloadAppDescription"
              defaultValue={brand?.downloadAppDescription}
              rules={{ required: 'Download brand title is required.' }}
              render={({ field: { onChange } }) => (
                <>
                  <input
                    type="text"
                    disabled={!downloadApp}
                    defaultValue={brand?.downloadAppDescription}
                    className={clsx('input w-full')}
                    onChange={(res: any) => {
                      onChange(res);
                      setUpdatedBrand({
                        ...updatedBrand,
                        downloadAppDescription: res.target.value,
                      });
                    }}
                    // {...field}
                  />
                </>
              )}
            />
          </div>
          <div
            style={{ width: '48%' }}
            className="flex flex-col bg-white rounded-2xl pr-4 py-4 w-full"
          >
            <h3 className="font-semibold my-3 text-[20px]">Download App Description</h3>
            <Controller
              control={control}
              name="downloadAppSubCopy"
              defaultValue={brand?.downloadAppSubCopy}
              // rules={{ required: 'Remaining Budget is required.' }}
              render={({ field: { onChange } }) => (
                <>
                  <input
                    type="text"
                    disabled={!downloadApp}
                    defaultValue={brand?.downloadAppSubCopy}
                    className={clsx('input w-full')}
                    onChange={(res: any) => {
                      onChange(res);
                      setUpdatedBrand({
                        ...updatedBrand,
                        downloadAppSubCopy: res.target.value,
                      });
                    }}
                    // {...field}
                  />
                </>
              )}
            />
          </div>
          <div
            style={{ width: '48%' }}
            className="flex flex-col bg-white rounded-2xl pl-4 py-4 w-full"
          >
            <h3 className="font-semibold my-3 text-[20px] ">Application URL</h3>
            <Controller
              control={control}
              name="downloadAppLink"
              defaultValue={brand?.downloadAppLink}
              rules={{ required: 'URL is required.' }}
              render={({ field: { onChange } }) => (
                <>
                  <input
                    type="text"
                    disabled={!downloadApp}
                    defaultValue={brand?.downloadAppLink}
                    className={clsx('input w-full')}
                    onChange={(res: any) => {
                      onChange(res);
                      setUpdatedBrand({
                        ...updatedBrand,
                        downloadAppLink: res.target.value,
                      });
                    }}
                  />
                </>
              )}
            />
          </div>
        </div>

        {location.pathname === `/brand/${brandId}` && (
          <>
            <h3 className="font-semibold my-3 text-[20px]">Download App Image</h3>
            <Controller
              control={control}
              name="downloadAppImage"
              defaultValue={brand && brand.downloadApp ? brand.downloadAppImage : ''}
              rules={{ required: 'Download app image is required.' }}
              render={({ field: { onChange } }) => (
                <ImageUploader
                  subText="or drop an image to upload"
                  criteria="Image size 500 x 560 px (.jpg .png). Maximum 5MB."
                  widthReq={500}
                  heightReq={560}
                  defaultImg={
                    brand && brand.downloadApp ? brand?.downloadAppImageUrl : ''
                  }
                  imageUrlCB={(res: any) => {
                    onChange(res);
                    setUpdatedBrand({ ...updatedBrand, downloadAppImage: res });
                  }}
                  imgType="APP_DOWNLOAD_IMAGE"
                />
              )}
            />

            <div className="text-red-500">
              {!downloadApp || (downloadApp && updatedBrand?.downloadAppImage)
                ? ''
                : 'Please Upload Download App Image'}
            </div>
          </>
        )}
      </div>

      {location.pathname === `/brand/${brandId}` && (
        <form className="flex flex-col gap-4 w-full">
          <div className="flex flex-col bg-white rounded-2xl p-8">
            <h3 className="font-semibold mb-3 text-[20px]">{t('cover-image')}</h3>
            <Controller
              control={control}
              name="coverImagePath"
              defaultValue={brand ? brand.coverImagePath : ''}
              // rules={{ required: 'Cover image is required.' }}
              render={({ field: { onChange } }) => (
                <ImageUploader
                  subText="or drop an image to upload"
                  criteria="Image size 1280 x 720 px (.jpg .png). Maximum 5MB."
                  widthReq={1280}
                  heightReq={720}
                  defaultImg={brand?.coverImageUrl ? brand?.coverImageUrl : ''}
                  imageUrlCB={(res: any) => {
                    onChange(res);
                    setUpdatedBrand({ ...updatedBrand, coverImagePath: res });
                  }}
                  imgType="BRAND_COVER"
                />
              )}
            />
          </div>

          <div className="flex flex-col bg-white rounded-2xl p-8">
            <h3 className="font-semibold mb-3 text-[20px]">Brand Logo Image</h3>
            <Controller
              control={control}
              name="logoImagePath"
              defaultValue={brand?.logoImagePath}
              // rules={{ required: 'Logo is required.' }}
              render={({ field: { onChange } }) => (
                <ImageUploader
                  subText="or drop an image to upload"
                  criteria="Image size 300 x 300 px (.jpg .png). Maximum 5MB."
                  logo
                  widthReq={300}
                  heightReq={300}
                  defaultImg={brand ? brand?.logoUrl : ''}
                  imageUrlCB={(res: any) => {
                    onChange(res);
                    setUpdatedBrand({ ...updatedBrand, logoImagePath: res });
                  }}
                  imgType="BRAND_LOGO"
                  // formError={errors?.logoImagePath?.message}
                />
              )}
            />
          </div>

          {/* Hidden QR Code */}
          <div style={{ position: 'absolute', top: '-9999px' }}>
            <div className="flex flex-row w-full flex-wrap">
              {imagesQR?.map((qrCodeDetails, i) => (
                <div className="pl-4" key={i}>
                  <div
                    ref={el => {
                      qrCodeReference.current[i] = el;
                    }}
                    style={{ position: 'relative', display: 'none' }}
                  >
                    <img
                      src={qrCodeDetails?.url}
                      alt="Background"
                      style={{ width: '170px', height: '230px' }}
                    />
                    <QRCode
                      style={{
                        width: qrCodeDetails?.width,
                        height: qrCodeDetails?.height,
                        top: qrCodeDetails?.top,
                        left: qrCodeDetails?.left,
                        position: 'absolute',
                        borderRadius: '5px',
                      }}
                      fgColor={qrCodeDetails.fgColor}
                      value={shortDynamicLink}
                      renderAs="svg"
                    />
                  </div>
                </div>
              ))}{' '}
            </div>
          </div>
        </form>
      )}

      <div className="flex flex-row justify-between w-full mt-4">
        <h2 className="font-semibold my-3 text-[24px]">Claim Samples</h2>

        <Button
          type="outline"
          onClick={() =>
            setClaimSampleSelection([
              ...claimSampleSelection,
              {
                id: '',
                brandId: '',
                title: '',
                startDate: '',
                endDate: '',
                startTime: '',
                endTime: '',
                description: '',
                file: { name: '', format: '', base64: '', url: '' },
              },
            ])
          }
        >
          Create Claim Sample
        </Button>
      </div>
      {claimSampleSelection?.length ? (
        claimSampleSelection?.map((fl: IClaimSampleSelection | any, i: number) => (
          <div className="w-full" key={fl.id}>
            <CreateClaim
              index={i}
              singleClaimSample={fl}
              setBackClaimSample={(obj: any) => {
                const arr: IClaimSampleSelection[] = [...claimSampleSelection];
                arr[i] = { ...arr[i], ...obj };
                setChangeCategorySelection(true);
                setClaimSampleSelection(arr);
              }}
              isBrand={brandId}
              removeBrandClaim={() => {
                const arr: IClaimSampleSelection[] = [...claimSampleSelection];
                delete arr[i];
                setClaimSampleSelection(arr?.filter(f => f));
              }}
            />
          </div>
        ))
      ) : (
        <div className="w-full rounded-xl flex flex-row justify-center">
          <p className="mb-3 mt-4 text-textSecondary">No Claim Sample available</p>
        </div>
      )}

      {location.pathname === `/brand/${brandId}` && (
        // <h3 className="font-semibold mb-6 text-[20px]">{t('brand-qr')}</h3>
        <div className="flex flex-row w-full justify-center items-center h-[8px] mt-4 my-2">
          <Button type="secondary" onClick={() => setBrandDeleteModalOpen(true)}>
            Delete Brand
          </Button>
        </div>
      )}

      {/* <div className="w-full bg-lightBlue rounded-xl p-4 h-[237px]">
        <h2>Claim Samples</h2>
        <div className="m-auto w-fit flex flex-col items-center mt-7">
          <Button
            type="outline"
            onClick={() =>
              setClaimSampleSelection([
                ...claimSampleSelection,
                {
                  id: '',
                  brandId: '',
                  title: '',
                  startDate: '',
                  endDate: '',
                  startTime: '',
                  endTime: '',
                  description: '',
                  file: { name: '', format: '', base64: '', url: '' },
                },
              ])
            }
          >
            Create Claim Sample
          </Button>
        </div>
      </div> */}

      <Modal
        type="small"
        isOpen={brandDeleteModalOpen}
        setIsOpen={setBrandDeleteModalOpen}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">Delete Brand</h2>
            <p>
              Are you sure you want to delete {brand ? brand.displayName : ''}?
              <div className="text-red-500">
                Note: All associated data including adverts, offers, and user following
                will be deleted.
              </div>
            </p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setBrandDeleteModalOpen(false)}>
              No
            </Button>
            <Button
              type="secondary"
              loading={brandDeleteLoader}
              onClick={() => deleteABrand()}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>

      <Modal type="small" isOpen={discardModalOpen} setIsOpen={setDiscardModalOpen}>
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              Are you sure you want to discard?
            </h2>
            <p>You will lose any progress made within this session.</p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setDiscardModalOpen(false)}>
              No, keep editing
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                window.location.reload();
                window.scrollTo(0, 0);
              }}
            >
              Yes, close and discard
            </Button>
          </div>
        </div>
      </Modal>

      {(isDirty || changeCategorySelection) && (
        <StickyFooter
          save={() =>
            location?.pathname === '/brand/create' ? onSubmitAdd() : onSubmitEdit()
          }
          brandError={brandError}
          saveLoading={editBrandLoading}
          discard={() => setDiscardModalOpen(true)}
        />
      )}

      <Modal
        type="small"
        isOpen={notificationModalOpen}
        setIsOpen={setNotificationModalOpen}
      >
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">
              Do you wish to send a notification
            </h2>
            <p>
              You will alert users that follow {brand ? brand.displayName : ''} that they
              are available on Tickle
            </p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setNotificationModalOpen(false)}>
              No
            </Button>
            <Button type="secondary" loading={notifyNewBrandLoading} onClick={() => {}}>
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Brand;
