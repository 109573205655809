import clsx from 'clsx';
import { FC, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getAllCountriesList } from '../../../../services/queries/userManagement';
import CreateAdOfferBoost from '../../../CreateBoost/CreateAdOfferBoost';
import DropdownMultiSelectCountry from '../../../DropdownMultiselect/DropdownMultiselectCountry';
import FileUpload from '../../../FileUpload/FileUpload';
import ImageUploader from '../../../ImageUploader/ImageUploader';
import Accordion from '../../../UI/Accordion/Accordion';
import Button from '../../../UI/Button/Button';
import Icon from '../../../UI/Icon/Icon';
import Modal from '../../../UI/Modal/Modal';
import Radio from '../../../UI/Radio/Radio';
import Checkbox from '../../../UI/Toggles/Checkbox/Checkbox';
import { AdFormSchema } from '../../AdForm/AdFormTypes';
import { OfferFormType } from '../OfferForm';

interface Props {
  index: number;
  offerIsActive: boolean;
  offer: OfferFormType;
  backCountry?: any;
}

const CreateOffer: FC<Props> = ({ index, offerIsActive, offer, backCountry }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [enableWeightage, setEnableWeightage] = useState<any>(false);
  const [enableHaloOffer, setEnableHaloOffer] = useState<any>(false);
  const { t } = useTranslation('offersLibrary');
  const codeOptions = [
    { id: '1', name: 'Codes', value: 'CODE' },
    { id: '2', name: 'URL', value: 'URL' },
  ];
  const [redemption, setRedemption] = useState(() => {
    if (offer.redemptionType) {
      return offer.redemptionType === 'CODE' ? codeOptions[0] : codeOptions[1];
    }
    return codeOptions[0];
  });

  const offerOptions = [
    { id: '1', name: 'Publish', value: 'Publish' },
    { id: '2', name: 'Draft', value: 'Draft' },
  ];
  const [offerCode, setOfferCode] = useState(() => {
    if (offer.offerStatus) {
      return offer.offerStatus === 'Draft' ? offerOptions[1] : offerOptions[0];
    }
    return offerOptions[0];
  });

  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = `${new Date()
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${new Date().getUTCMinutes().toString().padStart(2, '0')}`;

  const currentUpdateTime = `${new Date()
    .getUTCHours()
    .toString()
    .padStart(2, '0')}:${new Date().getUTCMinutes().toString().padStart(2, '20')}`;

  const {
    register,
    setValue,
    reset,
    trigger,
    getValues,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useFormContext<AdFormSchema>();

  const { append: appendBoost } = useFieldArray({
    control,
    name: `offers.${index}.boosts`,
  });
  const [inputTextValue, setInputTextValue] = useState<string>();

  useEffect(() => {
    setEnableWeightage(offer.enableWeightage);
    setEnableHaloOffer(offer.enableHalo);
  }, [offer]);

  const [country, setCountry] = useState<any>();
  const [selectedCountry, setSelectedCountry] = useState<any>([]);

  const preCountries = getValues(`offers.${index}.countries`);

  useEffect(() => {
    getAllCountriesList('', '', (res: any) => {
      if (res.isSuccess) {
        setCountry(JSON.parse(res.data));
        if (preCountries?.length) {
          setSelectedCountry(
            JSON.parse(res.data)?.filter((fl: any, i: number) => {
              return preCountries?.some((el: any) => fl?.code.includes(el));
            })
          );
        }
      } else {
        setCountry([]);
      }
    });
  }, []);

  const isUltimateEnabled = (boostId: string) => {
    const ultimateBoost = offer.boosts?.find(b => b.isUltimate);
    if (ultimateBoost == null) {
      return true;
    }

    if (ultimateBoost.id === boostId) {
      return true;
    }
    return false;
  };

  return (
    <div data-testid="create-offer-container">
      <Accordion
        defaultOpen
        type="offer"
        title={
          <>
            {/* <h1 className="w-[90px] text-left text-lg">Offer {index + 1}</h1> */}

            <h2 className="w-[340px] text-left text-ellipsis" aria-label="Title">
              {getValues(`offers.${index}.title`)}
            </h2>

            <div className="flex flex-col text-left">
              {getValues(`offers.${index}.offerCodes`) && (
                <div>
                  <span className="text-white">Codes used</span>
                  <span className="ml-4 text-white">{`${getValues(
                    `offers.${index}.offerCodes.available`
                  ).toLocaleString()} of ${getValues(
                    `offers.${index}.offerCodes.total`
                  ).toLocaleString()}`}</span>
                </div>
              )}
              <div className="flex flex-row text-white">
                <p>{t('dates')}</p>
                <div className="flex flex-row ml-4 text-white gap-2">
                  <p>{getValues('beginDate').split('-').reverse().join('/')}</p>-
                  <p>{getValues('endDate').split('-').reverse().join('/')}</p>
                </div>
              </div>
            </div>
          </>
        }
      >
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-6 bg-white rounded-md p-4 pb-11">
            <h2 className="text-lg font-semibold">{t('offer-details')}</h2>

            <div className="flex flex-row justify-between flex-wrap w-full">
              <div style={{ width: '48%' }} className=" flex flex-row  ">
                <label className="flex flex-col w-full relative font-semibold">
                  {t('offer-title')}
                  <input
                    type="text"
                    maxLength={50}
                    style={{ width: '100%' }}
                    className={clsx(
                      'input font-normal',
                      errors?.offers?.[index]?.title && 'border-error'
                    )}
                    {...register(`offers.${index}.title`, {
                      required:
                        offerCode?.value === 'Draft' ? false : 'Offer title is required.',
                      minLength: {
                        value: 4,
                        message: 'Offer title must be at least 4 characters.',
                      },
                      maxLength: 50,
                    })}
                  />
                  <span className="text-md absolute top-[74px] right-0 font-normal">
                    {offer.title?.length}/50
                  </span>
                  <p className="text-error mt-2 font-normal" role="alert">
                    {errors.offers?.[index]?.title?.message}
                  </p>
                </label>
              </div>

              <div style={{ width: '48%' }} className="pr-2 flex flex-row  ">
                <label className="flex flex-col w-full relative font-semibold">
                  {t('offer-sub-title')}
                  <input
                    type="text"
                    maxLength={115}
                    style={{ width: '100%' }}
                    className={clsx(
                      'input font-normal',
                      errors.offers?.[index]?.subtitle && 'border-error'
                    )}
                    {...register(`offers.${index}.subtitle`, {
                      required:
                        offerCode?.value === 'Draft'
                          ? false
                          : 'Offer sub text is required.',
                      minLength: {
                        value: 4,
                        message: 'Offer subtitle must be at least 4 characters.',
                      },
                      maxLength: 115,
                    })}
                  />
                  <span className="text-md absolute top-[74px] right-0 font-normal">
                    {offer.subtitle?.length}/115
                  </span>
                  <p className="text-error mt-2 font-normal" role="alert">
                    {errors.offers?.[index]?.subtitle?.message}
                  </p>
                </label>
              </div>
            </div>

            <p className="font-semibold">Countries</p>
            <Controller
              name={`offers.${index}.countries`}
              control={control}
              rules={{
                required: 'Please select atleast one country',
              }}
              render={({ field }) => (
                <DropdownMultiSelectCountry
                  people={country ?? []}
                  isCountry
                  categorySelected={selectedCountry ?? []}
                  changeCategorySelection={(flag: any) => {
                    console.log('Change back', flag);
                    field.onChange(flag.map((c: any) => c.code));
                  }}
                  singleBack={(e: any) => {
                    const includes = selectedCountry?.some(
                      (ch: any) => ch?.code === e?.code
                    );
                    let newSelection;
                    if (includes) {
                      newSelection = selectedCountry?.filter(
                        (ch: any) => ch?.code !== e?.code
                      );
                    } else {
                      newSelection = [...selectedCountry, e];
                    }
                    setSelectedCountry(newSelection);
                    field.onChange(newSelection.map((c: any) => c.code));
                  }}
                  backPeople={(item: any) => {
                    console.log('Selected countries', item);
                    console.log('Free Index Countr', offer);
                    field.onChange(item.map((c: any) => c.code));
                  }}
                />
              )}
            />

            <div className="flex flex-row justify-between w-full">
              <div style={{ width: '48%' }} className=" relative">
                <label>
                  <p className="font-semibold">{t('required-coin')}</p>
                  <input
                    type="number"
                    data-testid="id-input"
                    // disabled={adIsActive}
                    min={1}
                    className={clsx(
                      'input w-full',
                      errors?.offers?.[index]?.requiredCoins && 'border-error'
                    )}
                    {...register(`offers.${index}.requiredCoins`, {
                      required:
                        offerCode?.value === 'Draft'
                          ? false
                          : 'Coins in Numbers are required.',
                      minLength: {
                        value: 1,
                        message: 'Coins must be at least 1 characters.',
                      },
                      maxLength: 60,
                    })}
                  />
                  <p className="text-error mt-2" role="alert">
                    {errors?.offers?.[index]?.requiredCoins?.message}
                  </p>
                </label>
              </div>

              <div style={{ width: '48%' }} className=" relative">
                <div className="w-[220px]">
                  <p className="font-semibold mb-2">Offer Status</p>
                  <Controller
                    name={`offers.${index}.offerStatus`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <Radio
                        id="offer status"
                        name="offer-status"
                        options={offerOptions}
                        value={offerCode}
                        onChange={e => {
                          setOfferCode(e);
                          onChange(e.value);
                        }}
                      />
                    )}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-row w-full">
              <div className="mr-5">
                <Controller
                  name={`offers.${index}.enableWeightage`}
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange } }) => (
                    <Checkbox
                      type="tick"
                      name="enableWeightage"
                      checked={enableWeightage}
                      id="121"
                      label="Weightage to be used in Boost"
                      onChange={(res: any) => {
                        setEnableWeightage(!enableWeightage);
                        onChange(res);
                      }}
                      className="checked:!bg-primary !border-primary flex"
                    />
                  )}
                />
              </div>

              <div className="flex flex-row">
                {/* <Controller
                  name={`offers.${index}.enableHalo`}
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange } }) => (
                    <Checkbox
                      type="tick"
                      name="enableHaloOffer"
                      checked={enableHaloOffer}
                      id="enableHaloOffer"
                      label="Halo Offer"
                      onChange={(res: any) => {
                        setEnableHaloOffer(!enableHaloOffer);
                        onChange(res);
                      }}
                      className="checked:!bg-primary !border-primary flex"
                    />

                    // <Switch
                    //   checked={enableHaloOffer}
                    //   onChange={(e) => {
                    //     setEnableHaloOffer(!enableHaloOffer);
                    //     onChange(e);
                    //   }}
                    //   inputProps={{ 'aria-label': 'controlled' }}
                    // />
                  )}
                /> */}
                {/* <p className="font-semibold pt-2">{t('halo-offer')}</p> */}
              </div>
            </div>

            <div className="flex -flex-row w-full justify-between">
              <div style={{ width: '48%' }}>
                <label className="flex flex-col relative font-semibold">
                  {t('offer-about-this-offer')}
                  <textarea
                    // maxLength={1000}
                    style={{ height: '240px' }}
                    className={clsx(
                      'input w-full font-normal',
                      errors.offers?.[index]?.description && 'border-error'
                    )}
                    {...register(`offers.${index}.description`, {
                      required:
                        offerCode?.value === 'Draft'
                          ? false
                          : 'Offer description is required.',
                      minLength: {
                        value: 4,
                        message: 'Offer description must be at least 4 characters.',
                      },
                      // maxLength: 1000,
                    })}
                  />
                  <span className="text-md absolute -bottom-6 right-0 font-normal">
                    {offer.description?.length}/1000
                  </span>
                  <p className="text-error mt-2 font-normal" role="alert">
                    {errors.offers?.[index]?.description?.message}
                  </p>
                </label>
              </div>

              <div style={{ width: '48%' }}>
                <div className=" font-semibold">{t('offer-image')}</div>
                <Controller
                  control={control}
                  name={`offers.${index}.imagePath`}
                  rules={{
                    required:
                      offerCode?.value === 'Draft' ? false : 'Offer image is required.',
                  }}
                  render={({ field: { onChange } }) => (
                    <ImageUploader
                      subText="or drop an image to upload"
                      criteria="Image size 900 x 750 px (.jpg .png or .webp). Maximum 5MB."
                      widthReq={900}
                      heightReq={750}
                      defaultImg={offer.imageUrl || ''}
                      imageUrlCB={imagePath => {
                        onChange(imagePath);
                        trigger(`offers.${index}.imagePath`);
                      }}
                      setErrorAdvert={error =>
                        setError(`offers.${index}.imagePath`, error)
                      }
                      clearErrorsAdvert={clearErrors}
                      imgType="OFFER_IMAGE"
                      formError={errors?.offers?.[index]?.imagePath?.message}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          {/* <div className="bg-white rounded-md p-4">
          </div> */}
          <div className="flex flex-col bg-white rounded-md p-4 gap-4">
            <h2 className="text-lg font-semibold">{t('offer-schedule')}</h2>
            <p>{t('offer-schedule-text')}</p>

            <div className="flex flex-row w-full justify-between">
              <div style={{ width: '48%' }} className="flex flex-row justify-between">
                <label style={{ width: '48%' }} className="relative">
                  <p className="font-semibold">{t('start-date')}</p>
                  <Icon
                    name="date"
                    width="20px"
                    className="absolute top-[36px] left-4 z-10 pointer-events-none"
                  />
                  <input
                    type="date"
                    data-testid="start-date-input"
                    min={currentDate}
                    // disabled={offerIsActive}
                    id="input-start-date-picker"
                    className={clsx(
                      'input w-full pl-10',
                      errors?.offers?.[index]?.beginDate && 'border-error'
                    )}
                    {...register(`offers.${index}.beginDate`, {
                      required: 'Offer start date is required.',
                      onBlur: () => {
                        trigger([`offers.${index}.beginTime`]);
                      },
                      onChange: event => {
                        if (new Date(event.target.value) > new Date(offer.endDate)) {
                          setValue(`offers.${index}.endDate`, '');
                        }
                      },
                    })}
                  />
                  <p className="text-error mt-2" role="alert">
                    {errors?.offers?.[index]?.beginDate?.message}
                  </p>
                </label>
                <label style={{ width: '48%' }} className=" relative">
                  <p className="font-semibold">{t('start-time')}</p>
                  <Icon
                    name="time"
                    width="20px"
                    className="absolute top-[36px] left-4 z-10 pointer-events-none"
                  />
                  <input
                    type="time"
                    data-testid="start-time-input"
                    // disabled={offerIsActive}
                    className={clsx(
                      'input w-full pl-10',
                      errors?.offers?.[index]?.beginTime && 'border-error'
                    )}
                    {...register(`offers.${index}.beginTime`, {
                      required: 'Offer start time is required.',
                      onBlur: () => {
                        trigger([`offers.${index}.endTime`]);
                      },
                      validate: value => {
                        if (value && !offerIsActive) {
                          const val = value.replace(':', '');
                          const endTime = offer.endTime.replace(':', '');
                          const currTime = currentTime.replace(':', '');
                          if (offer.beginDate === offer.endDate && +val > +endTime) {
                            return 'Start time can not be later than end time.';
                          }
                          if (offer.beginDate === currentDate && +val < +currTime) {
                            return 'Start time can not be later than current time.';
                          }
                        }
                        return true;
                      },
                    })}
                  />
                  <span className="absolute top-[36px] right-3 text-dark-grey-text pointer-events-none">
                    UTC
                  </span>
                  <p className="text-error mt-2" role="alert">
                    {errors?.offers?.[index]?.beginTime?.message}
                  </p>
                </label>
              </div>
              <div style={{ width: '48%' }} className="flex flex-row  justify-between">
                <label style={{ width: '48%' }} className="relative">
                  <p className="font-semibold">{t('end-date')}</p>
                  <Icon
                    name="date"
                    width="20px"
                    className="absolute top-[36px] left-4 z-10 pointer-events-none"
                  />
                  <input
                    type="date"
                    data-testid="end-date-input"
                    // disabled={offerIsActive}
                    min={offer.beginDate}
                    className={clsx(
                      'input w-full pl-10',
                      errors?.offers?.[index]?.endDate && 'border-error'
                    )}
                    {...register(`offers.${index}.endDate`, {
                      required: 'Offer end date is required.',
                      onBlur: () => {
                        trigger([`offers.${index}.endTime`]);
                      },
                    })}
                  />
                  <p className="text-error mt-2" role="alert">
                    {errors?.offers?.[index]?.endDate?.message}
                  </p>
                </label>
                <label style={{ width: '48%' }} className=" relative">
                  <p className="font-semibold">{t('end-time')}</p>
                  <Icon
                    name="time"
                    width="20px"
                    className="absolute top-[36px] left-4 z-10 pointer-events-none"
                  />
                  <input
                    type="time"
                    data-testid="end-time-input"
                    // disabled={offerIsActive}
                    className={clsx(
                      'input w-full pl-10',
                      errors?.offers?.[index]?.endTime && 'border-error'
                    )}
                    {...register(`offers.${index}.endTime`, {
                      required: 'Offer end time is required.',
                      onBlur: () => {
                        trigger(`offers.${index}.beginTime`);
                      },
                      validate: value => {
                        if (!offerIsActive && offer.beginDate === offer.endDate) {
                          const val = value.replace(':', '');
                          const startTime = offer.beginTime.replace(':', '');
                          if (+val < +startTime) {
                            return 'End time can not be earlier than start time.';
                          }
                        }
                        return true;
                      },
                    })}
                  />
                  <span className="absolute top-[36px] right-3 text-dark-grey-text pointer-events-none">
                    UTC
                  </span>
                  <p className="text-error mt-2" role="alert">
                    {errors?.offers?.[index]?.endTime?.message}
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div className="flex flex-col bg-white rounded-md p-4 gap-4">
            <h2 className="text-lg font-semibold">Offer redemption</h2>

            <div className="flex flex-row justify-between flex-wrap w-full">
              <div style={{ width: '48%' }} className="flex flex-col w-full gap-4">
                <label htmlFor="Redemption-method" className="w-[220px] relative">
                  <p className="font-semibold mb-2">Redemption method</p>
                  <Controller
                    name={`offers.${index}.redemptionType`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { onChange } }) => (
                      <Radio
                        id="123"
                        name="Redemption-method"
                        disabled={
                          !!getValues(`offers.${index}.offerCodes`) ||
                          !!getValues(`offers.${index}.redemptionUrl`)
                        }
                        options={codeOptions}
                        value={redemption}
                        onChange={e => {
                          setRedemption(e);
                          onChange(e.value);
                        }}
                      />
                    )}
                  />
                </label>
              </div>
            </div>

            {redemption.id === '1' && (
              <Controller
                control={control}
                name={`offers.${index}.csvFile`}
                render={({ field: { onChange } }) => (
                  <FileUpload
                    type="CODES"
                    setCsvCodes={e => {
                      onChange(e);
                      clearErrors(`offers.${index}.csvFile`);
                    }}
                    setOfferError={err => {
                      setError(`offers.${index}.csvFile`, err);
                    }}
                    offerRedemptionCounts={getValues(`offers.${index}.offerCodes`)}
                  />
                )}
              />
            )}
            {redemption.id === '2' && (
              <div className="w-full">
                <p className="font-semibold">URL</p>
                <input
                  type="text"
                  id="redemption-input"
                  placeholder="www.urlexample.com/12356-wsdgbisAbc"
                  className={clsx(
                    'input w-full',
                    errors.offers?.[index]?.redemptionUrl && 'border-error'
                  )}
                  {...register(`offers.${index}.redemptionUrl`, {
                    required: 'Offer redemption URL is required.',
                    pattern: {
                      value:
                        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
                      message: 'Offer redemption URL is invalid.',
                    },
                  })}
                />
                <p className="opacity-60">Enter the URL which hosts your Offer</p>
              </div>
            )}

            <p className="opacity-60">(Remove URL / CSV to switch Redemption Method)</p>
          </div>
        </div>

        {offer.boosts?.map((boost, i) => {
          const isUltimate = isUltimateEnabled(boost.id);
          return (
            <CreateAdOfferBoost
              offerIndex={index}
              key={boost.id}
              boost={boost}
              totalBoosts={offer?.boosts?.length}
              checkEnableWeightage={{ enableWeightage, index: i }}
              index={i}
              isDisabled={offerIsActive}
              isUltimateEnabled={isUltimate}
            />
          );
        })}
        {/* {(offer.boosts || []).length < 2 && ( */}
        <div className="bg-white rounded-md p-4 flex justify-center">
          <Button
            type="outline"
            className="flex items-center justify-center gap-1 !w-[264px]"
            onClick={() => {
              const newBoost = {
                id: [...new Array(24)]
                  .map(() => Math.floor(Math.random() * 16).toString(16))
                  .join(''),
                type: 'STANDARD',
                redemptionType: 'CODE',
                offerStatus: 'Pubish',
                isUltimate: false,
              };

              appendBoost({
                ...newBoost,
              });
            }}
          >
            <Icon name="rocket" width="24" />
            {/* {(offer.boosts || []).length === 1 ? 'Add second Boost' : 'Add a Boost'} */}
            Add a new Boost
          </Button>
        </div>
        {/* )} */}

        <Button
          type="cancel"
          className="font-semibold self-end"
          onClick={() => {
            setModalOpen(true);
          }}
        >
          Remove Offer
        </Button>
      </Accordion>

      <Modal type="small" isOpen={modalOpen} setIsOpen={setModalOpen}>
        <div className="h-full flex flex-col justify-between">
          <div>
            <h2 className="text-[20px] font-semibold mb-3">Remove?</h2>
            <p>Are you sure you want to remove? You will lose any data entered</p>
          </div>
          <div className="flex justify-between">
            <Button type="text" onClick={() => setModalOpen(false)}>
              No, go back
            </Button>
            <Button
              type="secondary"
              onClick={() => {
                const newOffers = getValues('offers') || [];
                newOffers.splice(index, 1);
                setValue('offers', newOffers);
                reset();
                setModalOpen(false);
              }}
            >
              Yes, remove
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CreateOffer;
