import { FieldErrorsImpl } from 'react-hook-form';

const getAllFormErrors = (errors: FieldErrorsImpl) => {
  const flattenedFormErrors: string[] = [];
  console.log('errors', errors);

  Object.entries(errors ?? {}).forEach(error => {
    if (Array.isArray(error[1])) {
      flattenedFormErrors.push(...error[1].map(getAllFormErrors).flat());
    } else if (typeof error[1]?.message === 'string') {
      flattenedFormErrors.push(error[1].message);
    }
  });

  return flattenedFormErrors;
};

export default getAllFormErrors;
